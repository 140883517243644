import { queryClient } from "@/api/utils";
import { globalCss } from "@givenwell/stitches";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import { StrictMode, Suspense, lazy } from "react";
import { router } from "./router";

const Toaster = lazy(async () => {
  const { Toaster } = await import("@givenwell/components");
  return { default: Toaster };
});

export function App() {
  devtoolsPositioning();
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Suspense fallback={null}>
          <Toaster />
        </Suspense>
        {import.meta.env.DEV && (
          <Suspense fallback={null}>
            <Devtools />
          </Suspense>
        )}
      </QueryClientProvider>
    </StrictMode>
  );
}

const Devtools = lazy(() => import("./Devtools"));

const devtoolsPositioning = globalCss({
  ".TanStackRouterDevtools > button": {
    left: "initial",
    right: "calc(var(--tsrd-font-size) * 0.5)",
  },
  ".tsqd-open-btn-container": {
    bottom: "calc(var(--tsqd-font-size) + 30px) !important",
  },
});

import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { membersRoute } from "..";

export const memberRoute = createRoute({
  path: "$memberId",
  getParentRoute: () => membersRoute,
  beforeLoad: () => ({
    breadcrumb: "View Member",
  }),
  component: lazyRouteComponent(() => import("./+Member")),
});

import { createRoute, lazyRouteComponent, SearchSchemaInput } from "@tanstack/react-router";
import {
  fallback,
  InferOutput,
  number,
  object,
  optional,
  parse,
  picklist,
  pipe,
  string,
  toMinValue,
  transform,
} from "valibot";
import { adminRoute } from "..";

export const claimsRoute = createRoute({
  path: "claims",
  getParentRoute: () => adminRoute,
});

const searchSchema = object({
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
  member: fallback(optional(string()), undefined),
  id: fallback(optional(string()), undefined),
  status: fallback(optional(picklist(["pending", "approved", "declined"])), undefined),
  start: fallback(pipe(optional(string()), transform(optionalStringToOptionalDate)), undefined),
  end: fallback(pipe(optional(string()), transform(optionalStringToOptionalDate)), undefined),
});
type PartialSearchSchema = Partial<InferOutput<typeof searchSchema>>;

export const claimsIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => claimsRoute,
  component: lazyRouteComponent(() => import("./+Claims")),
  validateSearch: (search: PartialSearchSchema & SearchSchemaInput) => parse(searchSchema, search),
});

function optionalStringToOptionalDate(dateStr: string = "") {
  const date = new Date(dateStr);
  if (!isNaN(date.getTime())) {
    return date;
  }
  return undefined;
}

import { listingQuery } from "@/api/listings";
import { queryClient } from "@/api/utils";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { listingsRoute } from "..";

export const listingRoute = createRoute({
  path: "$listingId/$version",
  getParentRoute: () => listingsRoute,
  component: lazyRouteComponent(() => import("./+EditListingPage")),
  beforeLoad: () => ({
    breadcrumb: "Edit Listing",
  }),
  parseParams: ({ version, ...params }) => {
    const num = +version;
    if (!isFinite(num)) {
      throw new Error("Invalid listing version");
    }
    return {
      ...params,
      version: Math.round(num),
    };
  },
  stringifyParams: ({ version, ...params }) => ({
    ...params,
    version: version.toString(),
  }),
  loader: async ({ params }) => {
    queryClient.removeQueries(listingQuery(params.listingId, params.version));
    await queryClient.prefetchQuery(listingQuery(params.listingId, params.version));
  },
});

import { onboardingStateQuery } from "@/api/supplier";
import { queryClient } from "@/api/utils";
import { ordersRoute } from "@/pages/supplier/orders";
import { passesRoute } from "@/pages/supplier/passes";
import { legalRoute } from "@/pages/supplier/settings/legal";
import { skuManagementRoute } from "@/pages/supplier/sku";
import { checkAuth, checkSupplierRole } from "@/utils/router";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { rootRoute } from "..";
import { bookingsRoute } from "./bookings";
import { listingsIndexRoute, listingsRoute } from "./listings";
import { listingRoute } from "./listings/[listingId]";
import { messagesIndexRoute, messagesRoute } from "./messages";
import { conversationRoute } from "./messages/[conversationId]";
import { paymentsRoute } from "./payments";
import { salesIndexRoute, salesRoute } from "./sales";
import { saleRoute } from "./sales/[purchaseId]";
import { settingsRoute } from "./settings";
import { automationsRoute } from "./settings/automations";
import { businessIndexRoute, businessRoute } from "./settings/business";
import { calendarsIndexRoute, calendarsRoute } from "./settings/calendars";
import { calendarRoute, editCalendarIndexRoute } from "./settings/calendars/[calendarId]";
import { calendarConnectRoute } from "./settings/calendars/[calendarId]/connect";
import { calendarDisconnectRoute } from "./settings/calendars/[calendarId]/disconnect";
import { calendarExternalRoute } from "./settings/calendars/[calendarId]/external";
import { locationSettingsRoute } from "./settings/locations";
import { marketSettingsRoute } from "./settings/markets";
import { payoutRoute } from "./settings/payment";
import { profileSettingsRoute } from "./settings/profile";
import { refundSettingsIndexRoute, refundSettingsRoute } from "./settings/refund";
import { cancellationPoliciesRoute } from "./settings/refund/cancellation-policy";
import { cancellationPolicyRoute } from "./settings/refund/cancellation-policy/[policyId]";
import { teamIndexRoute, teamRoute } from "./settings/team";
import { teamMemberRoute } from "./settings/team/[userId]";
import { subscriptionsRoute } from "./subscriptions";
import { userSettingsRoute } from "./user-settings";

export const supplierRoute = createRoute({
  path: "supplier/$supplierId",
  getParentRoute: () => rootRoute,
  loader: async ({ params }) => {
    await checkAuth();
    await checkSupplierRole(params.supplierId);

    queryClient.prefetchQuery(onboardingStateQuery(params.supplierId));
  },
  component: lazyRouteComponent(() => import("./+Layout")),
});

export const supplierIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => supplierRoute,
  component: lazyRouteComponent(() => import("./+SupplierHome")),
});

// prettier-ignore
export const supplierRouteTree = supplierRoute.addChildren({
  supplierIndexRoute,
  paymentsRoute,
  bookingsRoute,
  subscriptionsRoute,
  passesRoute,
  ordersRoute,
  listingsRoute: listingsRoute.addChildren({
    listingsIndexRoute,
    listingRoute,
  }),
  messagesRoute: messagesRoute.addChildren({
    messagesIndexRoute,
    conversationRoute,
  }),
  salesRoute: salesRoute.addChildren({
    salesIndexRoute,
    saleRoute,
  }),
  settingsRoute: settingsRoute.addChildren({
    automationsRoute,
    businessRoute: businessRoute.addChildren({
      businessIndexRoute
    }),
    calendarsRoute: calendarsRoute.addChildren({
      calendarsIndexRoute,
      calendarRoute: calendarRoute.addChildren({
        editCalendarIndexRoute,
        calendarConnectRoute,
        calendarDisconnectRoute,
        calendarExternalRoute,
      }),
    }),
    legalRoute,
    locationSettingsRoute,
    marketSettingsRoute,
    payoutRoute,
    profileSettingsRoute,
    refundSettingsRoute: refundSettingsRoute.addChildren({
      refundSettingsIndexRoute,
      cancellationPoliciesRoute: cancellationPoliciesRoute.addChildren({
        cancellationPolicyRoute
      }),
    }),
    teamRoute: teamRoute.addChildren({
      teamIndexRoute, 
      teamMemberRoute
    }),
  }),

  userSettingsRoute,
  skuManagementRoute,
});

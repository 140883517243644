import { checkAdminRole, checkAuth } from "@/utils/router";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { rootRoute } from "..";

export const dashboardIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => dashboardRoute,
  component: lazyRouteComponent(() => import("./+Dashboard")),
});
export const usageDashboardRoute = createRoute({
  path: "usage",
  getParentRoute: () => dashboardRoute,
  component: lazyRouteComponent(() => import("./+UsageDashboard")),
});
export const costsDashboardRoute = createRoute({
  path: "costs",
  getParentRoute: () => dashboardRoute,
  component: lazyRouteComponent(() => import("./+CostsDashboard")),
});

export const dashboardRoute = createRoute({
  path: "dashboard",
  getParentRoute: () => rootRoute,
  beforeLoad: async () => {
    await checkAuth();
    await checkAdminRole();
  },
  component: lazyRouteComponent(() => import("./+Layout")),
});

import { createRoute, lazyRouteComponent, notFound } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, number, object, optional } from "valibot";
import { rootRoute } from "..";

export const newSupplierRoute = createRoute({
  path: "new-supplier",
  getParentRoute: () => rootRoute,
  validateSearch: valibotValidator(object({ pageIdx: fallback(optional(number()), undefined) })),
  beforeLoad: () => {
    throw notFound();
  },
  component: lazyRouteComponent(() => import("./+NewSupplier")),
});

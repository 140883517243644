import { createRoute, lazyRouteComponent, notFound } from "@tanstack/react-router";
import { rootRoute } from "..";

export const newOrganisationRoute = createRoute({
  path: "new",
  getParentRoute: () => rootRoute,
  beforeLoad: () => {
    throw notFound();
  },
  component: lazyRouteComponent(() => import("./+NewOrganisation")),
});

import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, number, object, optional, pipe, toMinValue } from "valibot";
import { supplierRoute } from "..";

const searchSchema = object({
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
});

export const ordersRoute = createRoute({
  path: "orders",
  getParentRoute: () => supplierRoute,
  component: lazyRouteComponent(() => import("./+Orders")),
  validateSearch: valibotValidator(searchSchema),
});

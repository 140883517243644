export const colors = {
  transparent: "transparent",
  black: "#000",
  white: "#fff",

  brand0: "#EFFAFA",
  brand500: "#5CC1BF",
  gray0: "#F1F0F1",
  gray50: "#f5f4f5",
  gray100: "#E3E2E4",
  gray200: "#C7C6CA",
  gray300: "#b9b7bc",
  gray400: "#928F97",
  gray500: "#78767C",
  gray600: "#5F5D63",
  gray700: "#47464A",
  gray800: "#313032",
  gray900: "#1C1B1D",

  red0: "#FEEDE9",
  red50: "#feeeea",
  red100: "#FCDBD3",
  red200: "#F9B7A6",
  red300: "#F78F76",
  red400: "#F3623E",
  red500: "#D14927",
  red600: "#A63A1F",
  red700: "#7E2C17",
  red800: "#852e19",
  red900: "#662313",

  orange50: "#fef3e6",
  orange100: "#fcdbb2",
  orange200: "#fac98d",
  orange300: "#f8b159",
  orange400: "#f7a139",
  orange500: "#f58a07",
  orange600: "#df7e06",
  orange700: "#ae6205",
  orange800: "#874c04",
  orange900: "#673a03",

  yellow0: "#FFFAE6",
  yellow50: "#fffae6",
  yellow100: "#FFF4CC",
  yellow200: "#ffe68a",
  yellow300: "#ffda54",
  yellow400: "#ffd333",
  yellow500: "#ffc800",
  yellow600: "#e8b600",
  yellow700: "#b58e00",
  yellow800: "#8c6e00",
  yellow900: "#6b5400",

  green0: "#E4F5E4",
  green50: "#e6f6e6",
  green100: "#C9ECC9",
  green200: "#90D790",
  green300: "#52C152",
  green400: "#0BA80B",
  green500: "#008E00",
  green600: "#007D00",
  green700: "#006E00",
  green800: "#005a00",
  green900: "#004500",

  teal0: "#E0F5F3",
  teal50: "#e9f8f5",
  teal100: "#bbe8e1",
  teal200: "#9addd3",
  teal300: "#6ccdbf",
  teal400: "#4fc4b2",
  teal500: "#23b59f",
  teal600: "#20a591",
  teal700: "#198171",
  teal800: "#136457",
  teal900: "#0f4c43",

  blue0: "#F0EFFF",
  blue50: "#f3edff",
  blue100: "#E0E0FF",
  blue200: "#C2C1FF",
  blue300: "#A4A2FF",
  blue400: "#8582FF",
  blue500: "#6461FF",
  blue600: "#4844E7",
  blue700: "#3633AE",
  blue800: "#252378",
  blue900: "#161546",

  cyan0: "#E4F3FB",
  cyan50: "#f1f4fd",
  cyan100: "#C8E7F7",
  cyan200: "#bfcef5",
  cyan300: "#a2b7f1",
  cyan400: "#90a9ee",
  cyan500: "#7494ea",
  cyan600: "#4844E7",
  cyan700: "#176187",
  cyan800: "#405181",
  cyan900: "#313e62",

  pink50: "#ffe6ff",
  pink100: "#ffb0fe",
  pink200: "#ff8afd",
  pink300: "#ff55fc",
  pink400: "#ff34fc",
  pink500: "#ff01fb",
  pink600: "#e801e4",
  pink700: "#b501b2",
  pink800: "#8c018a",
  pink900: "#6b0069",

  purple0: "#FBECFF",
  purple50: "#eae9f5",
  purple100: "#F7D8FF",
  purple200: "#EFB0FF",
  purple300: "#E685FF",
  purple400: "#DB51FF",
  purple500: "#C400F7",
  purple600: "#9D00C6",
  purple700: "#8300A5",
  purple800: "#6B0087",
  purple900: "#590070",

  transparent100: "rgba(0, 0, 0, 0.1)",
  transparent200: "rgba(0, 0, 0, 0.2)",
} as const;

export const easing = {
  standard: "cubic-bezier(0.2, 0, 0, 1)",
  accelerate: "cubic-bezier(0.2, 0, 1, 1)",
  decelerate: "cubic-bezier(0, 0, 0, 1)",
  emphasizedDecelerate: "cubic-bezier(0.05, 0.7, 0.1, 1.0)",
  emphasizedAccelerate: "cubic-bezier(0.3, 0.0, 0.8, 0.15)",
} as const;
